.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.firstcom .header {
  color: #ED7F23 !important;
}

.firstcom .login.login-button {
  background: #ED7F23 !important;
  color: #FFF !important;
}

.firstcom .page-layout__side-bar .page-layout__side-bar-item {
  font-size: 17px;
}

.firstcom .page-layout__side-bar .page-layout__side-bar-item:hover {
  color: #b95e13;
}

.firstcom .page-layout__side-bar .page-layout__side-bar-item.activated  {
  color: #FFF;
  background: #b95e13;

}

.firstcom {
  --bg-primary: #ED7F23;
  --bg-secondary: #131F34;
  --text-primary: #ffffff;
  --text-error: #9f3a38;
  --button-bg-primary: #ED7F23;
  --button-text-primary: #ffffff;
  --button-hover-primary: #131F34;
  --button-active-primary: #131F34;
}

/*
 *  Primary orange - #ED7F23
 *  Secondary dark blue - #131F34
 */