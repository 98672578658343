.radacct_conn {
  fill: rgb(71, 181, 66);
  background-color: rgb(71, 181, 66);
}

.radacct_reco {
  fill: rgb(241, 234, 113);
  background-color: rgb(241, 234, 113);
}

.radacct_diss {
  fill: rgb(207, 66, 83);
  background-color: rgb(207, 66, 83);
}

.radacct_default {
  fill: rgb(154, 154, 154);
  background-color: rgb(154, 154, 154);
}
