.background-primary {
  background: var(--bg-primary) !important;
  color: var(--text-primary) !important;
}

.border-primary {
  border: 1px solid var(--bg-primary) !important;
}

.no-border-radius {
  border-radius: 0 !important;
}