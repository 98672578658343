.zen-availability-results {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.zen-broadband-group .content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
}

.zen-broadband-group .product {
  border: 1px solid grey;
  cursor: pointer;
  border-radius: 0.3rem;
  width: 15rem;
  flex-basis: 15rem;
  flex-shrink: 0;
}

.zen-broadband-group .product__title {
  font-size: 32px;
  font-weight: 400;
  padding: 1rem;
  text-align: center;
  margin: 0;
  line-height: 1.2;
  height: 3.2em;
  white-space: break-spaces;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zen-broadband-group .product.selected {
  background: #dddddd;
}

.zen-broadband-group .product__info {
  border-top: 1px solid grey;
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.zen-broadband-group .product__info p {
  margin: 0;
}