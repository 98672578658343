.landing-page__container {
  font-family: 'Roboto', sans-serif;
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f9f9f9;
}

.landing-page__header {
  text-align: center;
  margin-bottom: 40px;
}

.landing-page__logo {
  max-width: 150px;
}

.landing-page__header h1 {
  font-size: 32px;
  margin-top: 10px;
  color: #333;
}

.landing-page__content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.landing-page__section {
  margin-bottom: 40px;
}

.landing-page__section h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #555;
}

.landing-page__nav-items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.landing-page__nav-link {
  background-color: #007bff;
  color: white;
  padding: 12px 25px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s ease;
  text-align: center;
  width: 200px;
}

.landing-page__nav-link:hover {
  background-color: #0056b3;
}

.landing-page__footer {
  text-align: center;
  margin-top: 40px;
}

.landing-page__logout-btn {
  background-color: #dc3545;
  color: white;
  padding: 10px 25px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.landing-page__logout-btn:hover {
  background-color: #c82333;
}
