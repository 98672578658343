.disabled {
    opacity:1 !important;
}

.ui.form .field.disabled>label, .ui.form .fields.disabled>label {
    opacity: 1 !important;
}

.ui.divider:not(.vertical):not(.horizontal) {
    border-top: 1px solid var(--bg-primary) !important;
    border-bottom: 1px solid var(--bg-primary) !important;
}

 .borderNone {
     border: none !important; 
}

.ui.selection.dropdown {
    margin-left: 2% !important;
    margin-right: 2% !important;
}

.uiButtonPorts {
    color: black !important;
    /*background-color: var(--bg-primary) !important; */
    background-color: white !important;
    box-shadow: 0 0 0 2px rgba(88, 86, 86, 0.233) !important;
    margin-left: 1%;
    align-items: center;
    
}

/* .uiButtonPorts:focus {
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(8, 8, 8, 0.973) !important;
  background-color: var(--bg-primary) !important;
  color: white !important;
 
  } */

  .uiButtonPortsActive {
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(8, 8, 8, 0.973) !important;
    background-color: var(--bg-primary) !important;
    color: white !important;
    }

    .uiButtonProvideActive {
        outline: none !important;
        box-shadow: 0 0 0 2px rgba(8, 8, 8, 0.973) !important;
        background-color: var(--bg-primary) !important;
        color: white !important;
        }

  .ui.textarea.margin {
    margin-left: 1% !important;
    margin-right: 1% !important;
  }


