.line-details__section {
  border-bottom: 1px solid var(--bg-primary);
  padding: 1.5em 1em;
}

.line-details__section:last-child {
  border-bottom: none;
}

.line-details__section-header {
  font-size: 22px;
}

.line-details__table {
  display: grid;
  margin-left: 1em;
  grid-template-columns: 20em 1fr;
  line-height: 2;
}

.line-details__table span:nth-child(odd) {
  font-weight: bold;
}