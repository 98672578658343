.field label {
  width: 10em !important;
}

.button-primary {
  background: var(--button-bg-primary) !important;
  color: var(--text-primary) !important;
}

.login__help-text {
  font-weight: bold;
  margin-bottom: 2em;
}

.login__error {
  display: block;
  color: var(--text-error);
  font-weight: bold;
  margin-top: -0.5em;
  margin-bottom: 1.25em;
}

.login__success {
  display: block;
  color: #a3c293;
  font-weight: bold;
  margin-top: -0.5em;
  margin-bottom: 1.25em;
}