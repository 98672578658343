.access-line-button .buttonOptionsTable {
  width: 100%;
  border-collapse: collapse;
}

.access-line-button th,
.access-line-button td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.working-line-takeover {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.working-line-takeover .ui.button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.restart-stopped-line {
  display: flex;
  justify-content: center;
}

.restart-stopped-line .ui.button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.access-line-option {
  display: flex;
  justify-content: center;
}

.access-line-option .ui.button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.selectedOption {
  background-color: #2185d0 !important;
  color: white !important;
}

.input-validation-error {
  border-color: red !important;
}