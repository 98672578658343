.page__header__logo {
  margin-right: 1.5em;
}

.page__content {
  padding: 4em;
}

.ui.inverted.menu {
  background: var(--bg-primary) !important;
  color: var(--text-primary) !important;
}

.page-layout__container {
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 100vh;
}

.page-layout__side-bar {
  display: flex;
  flex-direction: column;
  background: var(--bg-secondary);
}

.page-layout__logo {
  width: 90%;
  margin: 0 auto;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
}

.page-layout__side-bar-item {
  padding: 1.5rem 1.25rem;
  background: var(--button-bg-primary);
  color: var(--text-primary); 
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border-bottom: thick double grey;
}

.page-layout__side-bar-item:hover {
  color: var(--button-text-primary);
  background: var(--button-hover-primary);
}

.page-layout__side-bar-item.activated,
.page-layout__side-bar-item:active,
.page-layout__side-bar-item:focus {
  color: var(--button-text-primary);
  background: var(--button-active-primary);
  text-decoration: none;
}

.page-layout__side-bar-spacer {
  flex-grow: 1;
}

.page-layout__content {
  padding: 2rem;
  max-width: 102.2rem;
  margin: 0 auto;
}
